import { useEffect, useRef, useState } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';

import './App.css';
import MessageAI from "./MessageAI";
import { requestAIMessage } from './openai';

export default function Conversation(props) {
  const {config, goBack} = props;

  const [history, setHistory] = useState([{
    speaker: "ai",
    message: config.requiresProject ? "What is the URL of your Scratch project?" : config.firstMessage
  }]);

  const [responseBoxEnabled, setResponseBoxEnabled] = useState(false);
  const [isConversationOver, setIsConversationOver] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const responseBoxRef = useRef(null);

  // Focus on input when it's enabled
  useEffect(() => {
    if (responseBoxEnabled) {
      responseBoxRef.current?.focus();
    }
  }, [responseBoxEnabled]);

  useEffect(() => {
    const latestMessage = history[history.length - 1];
    if (latestMessage.speaker === "user") {
      scrollToBottom();
      setUserMessage("");
      setResponseBoxEnabled(false);
      requestAIMessage(config.system, history, handleNewAIMessage);
    }
  }, [history, config])

  function updateUserMessage(event) {
    setUserMessage(event.target.value); 
  }

  async function handleUserMessageKeyDown(event) {
    if (event.key !== "Enter") {
      return;
    }

    const newChat = {
      speaker: "user",
      message: userMessage,
    }

    if (config.requiresProject && history.length === 1) {

      const response = await fetch("/project", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "url": userMessage,
          }),
      });
      const blocks = await response.text();

      setUserMessage("");
      setHistory([
        {
          speaker: "ai",
          message: "What is the URL of your Scratch project?",
          // Message to override for use in OpenAI calls but not shown to user
          hiddenMessage:"What is the content of your Scratch project?",
        },
        {
          speaker: "user",
          message: userMessage,
          hiddenMessage: blocks,
        },
        {
          speaker: "ai",
          message: config.firstMessage,
        }
      ]);
      return;
    }

    setHistory(history => [...history, newChat]);
  }

  function handleNewAIMessage(message) {
    setHistory(history => [...history, {
      speaker: "ai",
      message,  
    }]);
  }

  function handleAIMessageComplete(message) {
    if (message.toLowerCase().includes(config.endTrigger)) {
      setIsConversationOver(true);
    } else {
      setResponseBoxEnabled(true);
    }
  }

  function scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  return (
    <div id="container">
      <div id="conversation">
        <h1>{config.title}</h1>
        <button
          onClick={goBack}
          style={{
            cursor: "pointer",
            fontSize: "16px",
            backgroundColor: "transparent",
            border: "none",
            padding: "0",
          }}
        >
          Go Back
        </button>
        <div id="intro">
          {config.image &&
          <img
            src={config.image}
            alt="Scratch project"
          />
          }
          <div>
            {config.setup}
          </div>
        </div>
        <hr />
        <br />
        {history.map((chat, i) => (
          chat.speaker === "ai" ?
          <MessageAI
            key={i}
            message={chat.message}
            isLast={i === history.length - i}
            onMessageCompletion={() => handleAIMessageComplete(chat.message)}
          /> :
          <FadeIn key={i} onComplete={scrollToBottom}>
            <div className="conversation-item user-response">{chat.message}</div>
          </FadeIn>
        ))}
      </div>
      {
        !isConversationOver &&
        <div id="response-section">
          <input
              autoFocus
              ref={responseBoxRef}
              disabled={!responseBoxEnabled}
              value={userMessage}
              onChange={updateUserMessage}
              className="response-box"
              onKeyDown={handleUserMessageKeyDown}
            />
        </div>
      }
    </div>
  );
}

