import { useState } from 'react';

import './App.css';
import { SITUATIONS } from './config';
import Conversation from './Conversation';

export default function App() {

  const [situationIndex, setSituationIndex] = useState(null);

  if (situationIndex !== null) {
    return (
      <Conversation
       config={SITUATIONS[situationIndex]}
       goBack={() => setSituationIndex(null)}
      />
    );
  };

  return (
    <div id="container">
      <h1>
        Chat with Getting Unstuck
      </h1>
      <div
        id="conversation"
        style={{marginBottom: "30px"}}
      >
         Each of these activities simulates a conversation with a
         fourth-grade student working on a project in the Getting Unstuck
         curriculum.
      </div>

      {SITUATIONS.map((config, i) =>
          <div key={i}>
            <button
              className="option-button"
              onClick={() => setSituationIndex(i)}
            >
              {config.title}
            </button>
          </div>
      )}
    </div>
  );
}

