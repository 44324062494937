// Component for AI-generated message.

import { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import scratchblocks from 'scratchblocks';

function escapeHtml(unsafe) {
  return unsafe
   .replace(/&/g, "&amp;")
   .replace(/</g, "&lt;")
   .replace(/>/g, "&gt;")
   .replace(/"/g, "&quot;")
   .replace(/'/g, "&#039;");
}

export default function ConversationItem(props) {
  const {message, onMessageCompletion} = props;

  const textResponse = useRef(null);

  useEffect(() => {
    const random = Math.floor(Math.random() * 9999999999999);
    const typed = new Typed(textResponse.current, {
      strings: [escapeHtml(message).replaceAll(/```([^\s]*)\n(.+)```/sg, `<pre class="blocks${random}">$2</pre>`).replaceAll("\n", "<br />")],
      typeSpeed: 15,
      showCursor: true,
      onComplete: (self) => {
        self.cursor.remove();
        scratchblocks.renderMatching(`pre.blocks${random}`, {
          style: 'scratch3',
        });
        onMessageCompletion();
      },
    });

    return () => {
      typed.destroy();
    };
  // eslint-disable-next-line
  }, []);

  return (
    <div className="conversation-item" style={{ minHeight: "auto" }}>
      <div className="conversation-text">
        <span ref={textResponse} />
      </div>
    </div>
  );
}