// const MODEL_NAME = "gpt-4-0125-preview";
const MODEL_NAME = "gpt-3.5-turbo-0125";
const OPENAI_API_KEY = "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";
const OPENAI_BASE_URL = "https://api.openai.com/v1";
const CHAT_COMPLETIONS_URL = `${OPENAI_BASE_URL}/chat/completions`;

export function requestAIMessage(systemMessage, history, handleNewAIMessage) {
    const payload = {
        model: MODEL_NAME,
        messages: [
            {role: "system", content: systemMessage.join(" ")},
            ...(history.map(chat => ({
                role: chat.speaker === "ai" ? "assistant" : "user",
                content: chat.hiddenMessage ?? chat.message,
            })))
        ]
    }
    fetch(CHAT_COMPLETIONS_URL, {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
    })
    .then((res) => res.json())
    .then((data) => {
        console.log(data);
        const response = data.choices[0].message.content;
        console.log(response);
        handleNewAIMessage(response);
    })
    .catch((err) => {
        console.log(err);
        handleNewAIMessage("ERROR: Something went wrong when continuing this conversation. See the logs for more details.")
    });
    console.log(payload);
}